import React from "react";
import Style from "./About.module.scss";
import Terminal from "./Terminal";
import { Box } from "@mui/material";
import { info } from "../../info/Info";

export default function About({ innerRef }) {
  const firstName = info.firstName.toLowerCase();

  function aboutMeText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            let {firstName}
            {info.lastName.toLowerCase()}
          </span>{" "}
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            alex = <span className={Style.green}></span>{" "}
          </span>
          {info.bio}
        </p>
        <span className={Style.brown}>console.log(alex);</span>
      </>
    );
  }

  function skillsText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            let alex_skills
            {info.lastName.toLowerCase()}
          </span>
        </p>
        <p>
          <span style={{ color: info.baseColor }}>alex_skills =</span>{" "}
          <span>
            'javascript, react, git, github, nodejs, bootstrap, html, css'
          </span>
        </p>
        <span className={Style.brown}>console.log(alex_skills);</span>{" "}
      </>
    );
  }

  function miscText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            let {firstName}_hobbies
            {info.lastName.toLowerCase()}
          </span>{" "}
        </p>
        <p>
          <span className={Style.green}>
            alex_hobbies <span>= </span>
          </span>
          {"["}
        </p>
        <ul>
          {info.hobbies.map((hobby, index) => (
            <li key={index}>
              '
              <Box component={"span"} mr={"1rem"}>
                {hobby.emoji}
              </Box>
              {hobby.label}' ,{" "}
            </li>
          ))}
          {"]"}
        </ul>
        <span className={Style.brown}>console.log(alex_hobbies);</span>
      </>
    );
  }

  return (
    <Box
      ref={innerRef}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      mt={"3rem"}
      id={"about"}
    >
      <Terminal text={aboutMeText()} />
      <Terminal text={skillsText()} />
      <Terminal text={miscText()} />
    </Box>
  );
}
