import self from "../img/self.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import mock4 from "../img/mock4.png";
import mock5 from "../img/mock5.png";
import mock6 from "../img/mock6.png";
import mock7 from "../img/mock8.png";
import mock8 from "../img/mock7.png";
export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
export let singlePage = false;

export const info = {
  firstName: "Alex",
  lastName: "",
  initials: "js",
  position: "Фронтенд-разработчик",
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "🌎",
      text: "Живу в Волгограде",
    },
    {
      emoji: "💼",
      text: "Разрабатываю web приложения",
    },
    {
      emoji: "📧",
      text: "sopnev@mail.ru",
    },
  ],
  socials: [
    {
      link: "https://www.instagram.com/selfxp34/",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "https://github.com/selfxp34",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://vk.com/self34",
      icon: "fa fa-vk",
      label: "vk",
    },
    {
      link: "https://t.me/AlexSopnevv",
      icon: "fa fa-telegram",
      label: "telegram",
    },
    {
      link: "https://wa.me/+79996257735",
      icon: "fa fa-whatsapp",
      label: "telegram",
    },
  ],
  bio: "'Привет! Меня зовут Алексей!. Я фронтенд разработчик с огромным энтузиазмом и стремлением к профессиональному росту'",
  skills: {
    proficientWith: [
      "javascript",
      "react",
      "git",
      "github",
      "bootstrap",
      "html5",
      "css3",
      "figma",
    ],
    exposedTo: ["nodejs", "python", "adobe illustrator"],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
    },
    {
      label: "programming",
      emoji: "💻",
    },
    {
      label: "movies",
      emoji: "🎥",
    },
    {
      label: "travel",
      emoji: "✈️",
    },
  ],
  portfolio: [
    {
      title: "Сайт для гордского портала",
      live: "https://vlgcity.ru",
      source: "https://github.com/selfxp34/website-vlgcity.ru",
      image: mock1,
    },
    {
      title: "Услуги по утеплению домов",
      live: "https://uteplenie34.ru/",
      source: "https://github.com/selfxp34/website-uteplenie34.ru",
      image: mock2,
    },
    {
      title: "Сайт охранного предприятия",
      live: "https://granitvolga.ru/",
      source: "https://github.com/selfxp34/website-granit",
      image: mock3,
    },
    {
      title: "Услуги по аэросъемке",
      live: "https://aero134.ru/",
      source: "https://github.com/selfxp34/website-aero134",
      image: mock4,
    },
    {
      title: "Услуги по отделке домов",
      live: "https://fasad-volgograd.ru/",
      source: "https://github.com/selfxp34/website-fasad-volgograd.ru",
      image: mock5,
    },
    {
      title: "React+Next.js интернет магазин",
      live: "https://shop-volgograd.ru/",
      source: "https://github.com/selfxp34/eshop-next-main-main",
      image: mock6,
    },
    {
      title: "Сайт о домашнем питомце",
      live: "https://timofeinevskii.ru",
      source: "https://github.com/selfxp34/website-timofeinevskii",
      image: mock7,
    },
    {
      title: "Новостная реализация сайта",
      live: "https://new-volgograd.ru/news/volganews.html",
      source: "https://github.com/selfxp34/website-vlgcity.ru",
      image: mock8,
    },
  ],
};
